import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import _nav from "./_myNav";
import { useAtom } from "jotai";
import { companyAtom } from "../../_atoms/company";
import { userAtom } from "../../_atoms/user";

const Nav = props => {
  const [company] = useAtom(companyAtom)
  const [user] = useAtom(userAtom)
  const toggleMobileSidebar = () => {
    let { enableMobileMenu, setEnableMobileMenu } = props;
    setEnableMobileMenu(!enableMobileMenu);
  };

  return (
    <Fragment>
      <MetisMenu content={_nav.Home} onSelected={toggleMobileSidebar} activeLinkFromLocation
        className="vertical-nav-menu " iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" />
      <h5 className="app-sidebar__heading" >Firma Bilgileri</h5>
      <MetisMenu content={_nav.Company} onSelected={toggleMobileSidebar} activeLinkFromLocation
        className="vertical-nav-menu " iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" />
      {company.owner === user._id &&
        <MetisMenu content={_nav.Admin} onSelected={toggleMobileSidebar} activeLinkFromLocation
          className="vertical-nav-menu " iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" />
      }
      <h5 className="app-sidebar__heading" >Muhasebe & Finans</h5>
      <MetisMenu content={_nav.FinanceServices} onSelected={toggleMobileSidebar} activeLinkFromLocation
        className="vertical-nav-menu " iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" />
      <h5 className="app-sidebar__heading" >Pazarlama</h5>
      <MetisMenu content={_nav.MarketingServices} onSelected={toggleMobileSidebar} activeLinkFromLocation
        className="vertical-nav-menu " iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" />
    </Fragment>
  );
}
const mapStateToProps = (state) => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav));
