import React from "react"
const Profil = React.lazy(() => import('./Pages/Profile/Profile'))
const Home = React.lazy(() => import('./Pages/Home/index'))
/* Ana Sayfa > Şirketim */
const MyCompany = React.lazy(() => import('./Pages/Company/MyCompany'))
const Admins = React.lazy(() => import('./Pages/Company/Admins'))
/* Ana Sayfa > Şirketim */
/* Ana Sayfa > Çalışan */
const Worker = React.lazy(() => import('./Pages/Workers/Worker'))
const Workers = React.lazy(() => import('./Pages/Workers/Workers'))
/* Ana Sayfa > Çalışan */
/* Finansal İşlemler > Banka */
const Banks = React.lazy(() => import('./Pages/Finances/Banks/Banks'))
const Bank = React.lazy(() => import('./Pages/Finances/Banks/Bank'))
const BankBranches = React.lazy(() => import('./Pages/Finances/Banks/BankBranches'))
const BankBranch = React.lazy(() => import('./Pages/Finances/Banks/BankBranch'))

const Categories = React.lazy(() => import('./Pages/Categories/Categories'))
const Category = React.lazy(() => import('./Pages/Categories/Category'))
const BankAccounts = React.lazy(() => import('./Pages/Finances/Banks/BankAccounts/BankAccounts'))
const BankAccount = React.lazy(() => import('./Pages/Finances/Banks/BankAccounts/BankAccount'))
/* Finansal İşlemler > Banka */
/* Finansal İşlemler > Kasa */
const Safes = React.lazy(() => import('./Pages/Finances/Safes/Safes/Safes'))
const Safe = React.lazy(() => import('./Pages/Finances/Safes/Safes/Safe'))
/* Finansal İşlemler > Kasa */
/* Finansal İşlemler > Cari */
const Caris = React.lazy(() => import('./Pages/Companies/Cari/Caris'))
const Cari = React.lazy(() => import('./Pages/Companies/Cari/Cari'))
const Handshakes = React.lazy(() => import('./Pages/Companies/Cari/Handshakes'))
const Handshake = React.lazy(() => import('./Pages/Companies/Cari/Handshake'))
/* Finansal İşlemler > Cari */
/* Finansal İşlemler > Fatura */
const Bills = React.lazy(() => import('./Pages/Companies/Bills/Bills'))
const Bill = React.lazy(() => import('./Pages/Companies/Bills/Bill'))
/* Finansal İşlemler > Fatura */
/* Finansal İşlemler > Sipariş */
const OrderRequests = React.lazy(() => import('./Pages/Finances/Orders/OrderRequests/Orders'))
const OrderRequest = React.lazy(() => import('./Pages/Finances/Orders/OrderRequests/Order'))
/* Finansal İşlemler > Sipariş */
/* Finansal İşlemler > İrsaliye */
const Waybills = React.lazy(() => import('./Pages/Companies/Waybills/Waybills'))
const Waybill = React.lazy(() => import('./Pages/Companies/Waybills/Waybill'))
/* Finansal İşlemler > İrsaliye */
/* Şube İşlemler > Şube */
const Branches = React.lazy(() => import('./Pages/Branches/Branches/Branches'))
const Branch = React.lazy(() => import('./Pages/Branches/Branches/Branch'))
/* Şube İşlemler > Şube */
/* Şube İşlemler > Depo */
const Warehouse = React.lazy(() => import('./Pages/Branches/Warehouses/Warehouse'))
const Warehouses = React.lazy(() => import('./Pages/Branches/Warehouses/Warehouses'))
/* Şube İşlemler > Depo */
const Products = React.lazy(() => import('./Pages/Products/Products'))
const Product = React.lazy(() => import('./Pages/Products/Product'))


const Outcomes = React.lazy(() => import('./Pages/Finances/Transactions/Outcomes'))
const Incomes = React.lazy(() => import('./Pages/Finances/Transactions/Incomes'))
const Graphs = React.lazy(() => import('./Pages/Finances/Transactions/Graphs'))
const EInvoice = React.lazy(() => import('./Pages/EOPS/EInvoice'))

const OpenDemands = React.lazy(() => import('./Pages/Marketing/OpenDemands'))
const OpenDemand = React.lazy(() => import('./Pages/Marketing/OpenDemand'))
const OpenDemandDetail = React.lazy(() => import('./Pages/Marketing/OpenDemandDetail'))
const Demands = React.lazy(() => import('./Pages/Marketing/Demands'))
const Offers = React.lazy(() => import('./Pages/Marketing/Offers'))
const Offer = React.lazy(() => import('./Pages/Marketing/Offer'))


const routes = [
    { path: '/', exact: true, name: 'TR-DİJİTAL', },
    { path: '/dashboard', name: 'Ana Sayfa', component: Home },
    { path: '/profil', name: 'Profil', component: Profil },
    /* Ana Sayfa > Şirketim */
    { path: '/firma-islemleri', name: 'Firma Detay', component: MyCompany },
    { path: '/firma-grup-tanimlari', name: 'Firma Detay', component: Categories },
    { path: '/firma-grup-detay', name: 'Firma Detay', component: Category },
    { path: '/yonetim', name: 'Firma Yönetim', component: Admins },

    /* Ana Sayfa > Çalışan */
    { path: '/ozluk-dosyalari', name: 'Calışan İşlemleri', component: Workers },
    { path: '/ozluk-detay', name: 'Çalışan Detay', component: Worker },
    { path: '/pozisyon-tanimlari', name: 'Calışan İşlemleri', component: Categories },
    { path: '/pozisyon-detay', name: 'Çalışan Detay', component: Category },
    /* Ana Sayfa > Çalışan */
    /* Ana Sayfa > Şirketim */
    /* Finansal İşlemler > Banka */
    { path: '/banka-islemleri', name: 'Banka İşlemleri', component: Banks },
    { path: '/banka-detay', name: 'Banka Detay', component: Bank },
    { path: '/banka-sube-islemleri', name: 'Banka Şube İşlemleri', component: BankBranches },
    { path: '/banka-sube-islemleri-detay', name: 'Banka Şube İşlemleri Detay', component: BankBranch },
    { path: '/banka-grup-tanimlari', name: 'Hesap Grup Tanımları', component: Categories },
    { path: '/banka-grup-detay', name: 'Hesap Grup Detay', component: Category },
    { path: '/banka-hesaplari', name: 'Banka Hesapları', component: BankAccounts },
    { path: '/banka-hesap-detaylari', name: 'Banka Hesap Detayları', component: BankAccount },
    /* Finansal İşlemler > Banka */
    /* Finansal İşlemler > Kasa */
    { path: '/kasa-grup-tanimlari', name: 'Kasa Grup Tanımları', component: Categories },
    { path: '/kasa-grup-detay', name: 'Kasa Grup Detay', component: Category },
    { path: '/kasa-islemleri', name: 'Kasa İşlemleri', component: Safes },
    { path: '/kasa-detay', name: 'Kasa Detay', component: Safe },
    /* Finansal İşlemler > Kasa */
    /* Finansal İşlemler > Urun ve Servisler */
    { path: '/urunler', name: 'Kasa Detay', component: Products },
    { path: '/urun-detay', name: 'Kasa Detay', component: Product },
    { path: '/urun-kategorileri', name: 'Kasa Detay', component: Categories },
    { path: '/urun-kategori-detay', name: 'Kasa Detay', component: Category },
    { path: '/hizmetler', name: 'Kasa Detay', component: Products },
    { path: '/hizmet-detay', name: 'Kasa Detay', component: Product },
    { path: '/hizmet-kategorileri', name: 'Kasa Detay', component: Categories },
    { path: '/hizmet-kategori-detay', name: 'Kasa Detay', component: Category },
    /* Finansal İşlemler > Urun ve Servisler */
    /* Finansal İşlemler > Cari */
    { path: '/cari-islemleri', name: 'Cari İşlemleri', component: Caris },
    { path: '/cari-detay', name: 'Cari Detay', component: Cari },
    { path: '/handshakes', name: 'Cari İşlemleri', component: Handshakes },
    { path: '/handshake-detay', name: 'Cari Detay', component: Handshake },
    { path: '/cari-grup-tanimlari', name: 'Cari Grup Tanımı', component: Categories },
    { path: '/cari-grup-detay', name: 'Cari Grup Detay', component: Category },
    /* Finansal İşlemler > Cari */
    /* Finansal İşlemler > Fatura */
    { path: '/fatura', name: 'Fatura İşlemleri', component: Bills },
    { path: '/fatura-detay', name: 'Fatura Detay', component: Bill },
    { path: '/fatura-grup-islemleri', name: 'Fatura İşlemleri', component: Categories },
    { path: '/fatura-grup-detay', name: 'Fatura Detay', component: Category },
    /* Finansal İşlemler > Fatura */
    /* Finansal İşlemler > Sipariş */
    { path: '/siparis-talep-islemleri', name: 'Sipariş İşlemleri', component: OrderRequests },
    { path: '/siparis-detay', name: 'Sipariş Detay', component: OrderRequest },
    { path: '/siparis-teklif-islemleri', name: 'Sipariş İşlemleri', component: OrderRequests },
    { path: '/siparis', name: 'Sipariş İşlemleri', component: OrderRequests },
    { path: '/siparis-grup-islemleri', name: 'Sipariş İşlemleri', component: Categories },
    { path: '/siparis-grup-detay', name: 'Sipariş Detay', component: Category },
    /* Finansal İşlemler > Sipariş */
    /* Finansal İşlemler > İrsaliye */
    { path: '/irsaliye', name: 'Sipariş İşlemleri', component: Waybills },
    { path: '/irsaliye-detay', name: 'Sipariş Detay', component: Waybill },
    { path: '/irsaliye-grup-islemleri', name: 'Sipariş İşlemleri', component: Categories },
    { path: '/irsaliye-grup-detay', name: 'Sipariş Detay', component: Category },
    /* Finansal İşlemler > İrsaliye */
    /* Şube İşlemler > Şube */
    { path: '/sube-islemleri', name: 'Şube İşlemleri', component: Branches },
    { path: '/sube-detay', name: 'Şube Detay', component: Branch },
    { path: '/sube-grup-tanimlari', name: 'Şube Grup Tanımları', component: Categories },
    { path: '/sube-grup-detay', name: 'Şube Grup Detay', component: Category },
    { path: '/departman', name: 'Şube İşlemleri', component: Branches },
    { path: '/departman-detay', name: 'Şube Detay', component: Branch },
    { path: '/departman-grup-tanimlari', name: 'Şube Grup Tanımları', component: Categories },
    { path: '/departman-grup-detay', name: 'Şube Grup Detay', component: Category },
    /* Şube İşlemler > Şube */
    /* Şube İşlemler > Depo */
    { path: '/depo-islemleri', name: 'Depo İşlemleri', component: Warehouses },
    { path: '/depo-detay', name: 'Depo Detay', component: Warehouse },
    { path: '/depo-grup-tanimlari', name: 'Depo Grup Tanımları', component: Categories },
    { path: '/depo-grup-detay', name: 'Depo Grup Detay', component: Category },
    /* Şube İşlemler > Depo */

    { path: '/giderler', name: 'Giderler', component: Outcomes },
    { path: '/gider-kategorileri', name: 'Gider Kategorileri', component: Categories },
    { path: '/gider-kategori-detay', name: 'Gider Kategorileri', component: Category },
    { path: '/gelirler', name: 'Gelirler', component: Incomes },
    { path: '/gelir-kategorileri', name: 'Gelir Kategorileri', component: Categories },
    { path: '/gelir-kategori-detay', name: 'Gelir Kategorileri', component: Category },
    { path: '/gelir-gider-grafikleri', name: 'Gelir Gider Grafikleri', component: Graphs },

    { path: '/e-fatura', name: 'E Fatura', component: EInvoice },

    { path: '/acik-talepler', name: 'Açık Talepler', component: OpenDemands },
    { path: '/yeni-acik-talep', name: 'Yeni Açık Talep', component: OpenDemand },
    { path: '/acik-talep-detay', name: 'Açık Talepler', component: OpenDemandDetail },
    { path: '/talepler', name: 'Talepler', component: Demands },
    { path: '/teklifler', name: 'Teklifler', component: Offers },
    { path: '/teklif-detay', name: 'Teklif Detay', component: Offer },

]

export default routes;