import React, { Fragment, useState } from "react";
import {
  Popover,
  Nav,
  NavLink,
  Col,
  Row,
  NavItem,
  FormGroup,
  Label,
  Button,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { Link } from "react-router-dom";
import CompaniesDialog from "./CompaniesDialog";
import { useAtom } from "jotai";
import { companiesAtom } from "../../../_atoms/company";

const MegaMenu = () => {
  const [companies] = useAtom(companiesAtom)
  const [companiesDialog, setCompaniesDialog] = useState(false)
  const [state, setState] = useState({
    dropdownOpen: false,
    popoverOpen: false,
  });

  const handleCompaniesDialogToggle = () => {
    setCompaniesDialog(!companiesDialog)
  }

  const toggle = () => {
    setState({
      dropdownOpen: !state.dropdownOpen,
      popoverOpen: !state.popoverOpen,
    });
  }

  return (
    <Fragment>
      <Nav className="header-megamenu">
        <NavItem>
          {companies.length > 1 &&
            <Button color='primary' onClick={handleCompaniesDialogToggle} outline>Firma Değiştir</Button>
          }
          {/* <NavLink href="#" onClick={toggle} id="PopoverMegaMenu">
            Firmalar
            <FontAwesomeIcon className="ms-2 opacity-5" icon={faAngleDown} />
          </NavLink> */}
        </NavItem>
        {/* <Popover className="rm-max-width" placement="bottom-start" fade={false} trigger="legacy"
          isOpen={state.popoverOpen} target="PopoverMegaMenu" toggle={toggle}>
          <div className="p-3">
            <Row>
              <Col sm="12">
                <FormGroup>
                  <Label>Firmalar</Label>
                  <Select placeholder="Kayıtlı Firmalar"
                    options={[
                      { value: "A Firması", label: "A Firması" },
                      { value: "B Firması", label: "B Firması" },
                      { value: "B Firması", label: "B Firması" },
                    ]}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
        </Popover> */}
      </Nav>
      <CompaniesDialog
        open={companiesDialog}
        toggle={handleCompaniesDialogToggle}
      />
    </Fragment>
  );
}

export default MegaMenu;
