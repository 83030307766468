import React, { Fragment } from "react";
import AppHeader from "../Layout/AppHeader";
import AppSidebar from "../Layout/AppSidebar";
import AppFooter from "../Layout/AppFooter";
import ThemeOptions from "../Layout/ThemeOptions";
import { Col, Row } from "reactstrap";
const Layout = props => {
    return (
        <Fragment>
            {/*<ThemeOptions/>*/}
            <AppHeader />
            <div className="app-main">
                <AppSidebar />
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        <Row>
                            <Col xs={12}>
                                {props.children}
                            </Col>
                        </Row>
                    </div>
                    {/*<AppFooter />*/}
                </div>
            </div>
        </Fragment>
    )
}
export default Layout;