export const Home = [
  {
    icon: "pe-7s-display2",
    label: "Anasayfa",
    content: [
      {
        label: "Anasayfa",
        to: "#/",
      },
      // {
      //   label: "Firma Ara",
      //   to: "#/firma-ara",
      // },
      // {
      //   label: "Ürün Ara",
      //   to: "#/urun-ara",
      // },
      // {
      //   label: "Hizmet Ara",
      //   to: "#/hizmet-ara",
      // },
    ]
  },
];

export const Company = [
  {
    icon: "lnr-apartment",
    label: "Firma",
    content: [
      {
        label: "Firma Bilgileri",
        to: "#/firma-islemleri",
      },
      {
        label: "Şubeler",
        to: "#/sube-islemleri",
      },
      {
        label: "Departmanlar",
        to: "#/departman",
      },
      {
        label: "Depolar",
        to: "#/depo-islemleri",
      },
    ],
  },
  {
    icon: "pe-7s-ticket",
    label: "Ürünler ve Hizmetler",
    content: [
      {
        label: "Ürünler",
        to: "#/urunler",
      },
      {
        label: "Hizmetler",
        to: "#/hizmetler",
      },
    ]
  },
]

export const Admin = [
  {
    label: "Firma Yönetim",
    icon: "pe-7s-home",
    to: "#/yonetim",
  }
]

export const FinanceServices = [
  {
    icon: "pe-7s-id",
    label: "Cari Hesaplar",
    content: [
      {
        label: "Cari Hesaplar",
        to: "#/cari-islemleri",
      },
      {
        label: "El Sıkışmalar",
        to: "#/handshakes",
      },
    ]
  },
  {
    icon: "pe-7s-note",
    label: "Gelir/Gider",
    content: [
      {
        label: "Dönem Gelirleri",
        to: "#/gelirler",
      },
      {
        label: "Dönem Giderleri",
        to: "#/giderler",
      },
      {
        label: "Grafikler",
        to: "#/gelir-gider-grafikleri",
      },
      {
        label: "Dönem Arşiv",
        to: "#/donem-arşivi",
      },
    ]
  },
  {
    icon: "pe-7s-note2",
    label: "E-işlemler",
    content: [
      // {
      //   label: "E-irsaliye",
      //   to: "#/e-irsaliye",
      // },
      {
        label: "E-fatura",
        to: "#/e-fatura",
      },
      // {
      //   label: "E-defter",
      //   to: "#/e-defter",
      // },
    ],
  },
  // {
  //   icon: "pe-7s-safe",
  //   label: "Kasa ve Banka",
  //   content: [
  //     {
  //       label: "Kasalar",
  //       to: "#/kasa-islemleri",
  //     },
  //     {
  //       label: "Banka Hesapları",
  //       to: "#/banka-hesaplari",
  //     },
  //   ],
  // },
  {
    icon: "pe-7s-id",
    label: "Özkaynaklar",
    content : [
      {
        label: "İş Gücü",
        to: "#/ozluk-dosyalari",
      },
      // {
      //   label: "Demirbaş",
      //   to: "#/demirbas",
      // },
    ]
  },
  // {
  //   icon: "pe-7s-note2",
  //   label: "Mali Veriler",
  //   content: [
  //     {
  //       label: "Mizan",
  //       to: "#/mizan",
  //     },
  //     {
  //       label: "Gelir Tablosu",
  //       to: "#/beyannameler",
  //     },
  //     {
  //       label: "Vergi Levhası",
  //       to: "#/beyannameler",
  //     },
  //     {
  //       label: "Bilanço",
  //       to: "#/beyannameler",
  //     },
  //   ],
  // },
  // {
  //   icon: "pe-7s-note2",
  //   label: "Beyannameler",
  //   content: [
  //     {
  //       label: "KDV",
  //       to: "#/kdv",
  //     },
  //     {
  //       label: "Gelir/Kurumlar Vergisi",
  //       to: "#/kurumlar",
  //     },
  //     {
  //       label: "Gelir Geçici Vergi",
  //       to: "#/kurumlar",
  //     },
  //     {
  //       label: "Muhtasar",
  //       to: "#/muhtasar",
  //     },
  //   ],
  // },
  // {
  //   icon: "pe-7s-note2",
  //   label: "Raporlar",
  //   content: [
  //     {
  //       label: "KDV",
  //       to: "#/kdv",
  //     },
  //     {
  //       label: "Gelir/Kurumlar Vergisi",
  //       to: "#/kurumlar",
  //     },
  //     {
  //       label: "Gelir Geçici Vergi",
  //       to: "#/kurumlar",
  //     },
  //     {
  //       label: "Muhtasar",
  //       to: "#/muhtasar",
  //     },
  //   ],
  // },
];

const MarketingServices = [
  {
    icon: "pe-7s-note2",
    label: "Teklif/Talep",
    content: [
      {
        label: "Açık Talepler",
        to: "#/acik-talepler",
      },
      {
        label: "Teklifler",
        to: "#/teklifler",
      },
    ]
  },
  {
    icon: "pe-7s-note2",
    label: "Siparişler",
    to: "#/siparis",
    // content: [
    //   {
    //     label: "Sipariş",
    //     to: "#/siparis",
    //   },
    // ],
  },
]


export default { Home, Company, Admin, FinanceServices, MarketingServices }