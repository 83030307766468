import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { withRouter } from "react-router-dom";
import { useAtom } from "jotai"
import { withCookies } from 'react-cookie';
import { auth } from './_services/user'

import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';

import AppMain from "./Layout/AppMain";
import { userAtom } from "./_atoms/user";
import Loader from "react-loaders";
import { companyAtom } from "./_atoms/company";

const Login = React.lazy(() => import("./Pages/UserPages/Login/index"));
const SingUp = React.lazy(() => import("./Pages/UserPages/SingUp"));
const Waiting = React.lazy(() => import("./Pages/UserPages/Waiting/index"));
//const ForgotPassword = React.lazy(() => import("./Pages/UserPages/ForgotPassword"));
const Application = React.lazy(() => import("./Pages/UserPages/Application/index"));

const App = props => {

  const [user, setUser] = useAtom(userAtom)
  const { token } = props.cookies.cookies
  const [company] = useAtom(companyAtom)

  const { loggedIn } = user

  useEffect(() => {
    if (token && token !== '') {
      auth(token)
        .then((res) => {
          if (res.data && res.data.code && res.data.code === 200) {
            setUser({ ...res.data.data, loggedIn: true, token: token });
          } else {
            document.cookie = "token="
          }
        });
    }
  }, [token])

  let {
    colorScheme,
    enableFixedHeader,
    enableFixedSidebar,
    enableFixedFooter,
    enableClosedSidebar,
    closedSmallerSidebar,
    enableMobileMenu,
    enablePageTabsAlt,
  } = props;

  return (
    <HashRouter>
      <React.Suspense fallback={<Loader />}>
        <Switch>
          {/*<Route exact path="/yeni-sifre" name="Forgot Password" render={props => { return loggedIn ? <Redirect to='/' /> : <ForgotPassword {...props} /> }} />*/}
          <Route exact path="/application" name="Application Page" render={props => { return loggedIn ? <Application {...props} /> : <Redirect to='/login' /> }} />
          <Route exact path="/kayit-ol" name="Sing Up" render={props => { return loggedIn ? <Redirect to='/' /> : <SingUp {...props} /> }} />
          <Route exact path="/waiting" name="Waiting Page" render={props => { return loggedIn ? (company._id ? <Redirect to='/' /> : <Waiting {...props} />) : <Redirect to='/login' /> }} />
          <Route exact path="/login" name="Login Page" render={props => { return loggedIn ? <Redirect to='/' /> : <Login {...props} /> }} />
          <Route path="/" name="Anasayfa" render={({ width }) => {
            return (loggedIn) ? (company._id ?
              <Fragment>
                <div
                  className={cx(
                    "app-container app-theme-" + colorScheme,
                    { "fixed-header": enableFixedHeader },
                    { "fixed-sidebar": enableFixedSidebar || width < 1250 },
                    { "fixed-footer": enableFixedFooter },
                    { "closed-sidebar": enableClosedSidebar || width < 1250 },
                    {
                      "closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
                    },
                    { "sidebar-mobile-open": enableMobileMenu },
                    { "body-tabs-shadow-btn": enablePageTabsAlt }
                  )}>
                  <AppMain />
                </div>
              </Fragment> : <Redirect to='/waiting' />) : <Redirect to='/login' />
          }} />

        </Switch>
      </React.Suspense>
    </HashRouter>
  )
}

const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default withCookies(withRouter(connect(mapStateToProp)(App)));
