import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { useAtom } from 'jotai'
import React from 'react'
import { Row, Col, Card, Button, CardFooter } from 'reactstrap'
import { companiesAtom, companyAtom } from '../../../_atoms/company'

import avatar1 from "../../../assets/utils/images/avatars/1.jpg";
import bg2 from "../../../assets/utils/images/dropdown-header/city2.jpg";
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import { Link } from 'react-router-dom/cjs/react-router-dom'

const CompaniesDialog = (props) => {
    const { open, toggle } = props
    const [companies] = useAtom(companiesAtom)
    const [, setCompany] = useAtom(companyAtom)
    const handleClose = () => {
        toggle()
    }

    const router = useRouteMatch()

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen
            style={{ zIndex: 1008 }}
        >
            <DialogTitle>
                <h4>Firma Değiştir</h4>
            </DialogTitle>
            <DialogContent>
                <Row>
                    {companies.map((a, index) => {
                        return (
                            <Col sm='12' md='3' key={a._id}>
                                <Card className="card-shadow-primary card-border mb-3">
                                    <div className="dropdown-menu-header">
                                        <div className="dropdown-menu-header-inner bg-primary">
                                            <div className="menu-header-image"
                                                style={{
                                                    backgroundImage: "url(" + bg2 + ")",
                                                }}>
                                            </div>
                                            <div className="menu-header-content">
                                                <div className="avatar-icon-wrapper avatar-icon-lg">
                                                    <div className="avatar-icon rounded btn-hover-shine">
                                                        <img src={a.avatar || avatar1} alt="Avatar 5" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <h5 className="menu-header-title">{a.title}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <CardFooter className="text-center d-block">
                                        <Link href="./#/dashboard">
                                            <Button outline onClick={() => {
                                                toggle()
                                                setCompany(a)
                                            }} className="border-0 btn-transition" color="success">
                                                Yönet
                                            </Button>
                                        </Link>
                                    </CardFooter>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
            </DialogContent>
        </Dialog>
    )
}

export default CompaniesDialog